import "./styles.css";

import Details from "../../pages-pa/Details";

const PAPubOverview = ({ guest }) => {
  return (
    <div>
      <div className="overview">
        <div className="pa-container">
          <Details />
        </div>
      </div>

      <div className="help">
        Questions? Please contact {guest.registration.name} with{" "}
        {guest.registration.company} at {guest.registration.phone} or email{" "}
        <a href={`mailto:${guest.registration.email}`}>
          {guest.registration.email}
        </a>
        .
      </div>
    </div>
  );
};

export default PAPubOverview;
