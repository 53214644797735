import Hdg from "../../shared/Hdg";

import Details from "../Details";

import "../Details/styles.css";

const DashDetails = () => {
	return (
		<div>
			<Hdg text="Event Details" />

			<div className="overview">
				<div
					className="logo-container"
					style={{ backgroundColor: "rgba(0, 92, 185, 0.1)", padding: "0" }}
				>
					<img
						src="https://cmegrouptourchampionship.com/k-api/assets/wld-logo.png"
						alt="WLD Logo"
						style={{ width: "50%", padding: "0" }}
					/>
				</div>
			</div>

			<div className="details" style={{ marginTop: "25px" }}>
				<Details />
			</div>
		</div>
	);
};

export default DashDetails;
