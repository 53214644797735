import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import Alert from "./shared/Alert";

import Contact from "./pages/Contact";

import App from "./App";
import App2 from "./App2";

/* uncomment for testing locally 
contact, wldregistration, paregistration;
window.cmeformsSettings = {
	id: "paregistration",
	closed: false,
};
*/

let element = (
	<Alert data={{ type: "error", text: "An error has occurred." }} />
);

if (window.cmeformsSettings) {
	const { id, closed } = window.cmeformsSettings;

	if (id === "wldregistration") {
		element = closed ? (
			<Alert
				data={{
					type: "error",
					text: "Women’s Leadership Day registration is now closed.",
				}}
			/>
		) : (
			<App />
		);
	} else if (id === "paregistration") {
		element = closed ? (
			<Alert
				data={{
					type: "error",
					text: "Pro-Am registration is now closed.",
				}}
			/>
		) : (
			<App2 />
		);
	} else if (id === "contact") {
		element = <Contact />;
	}
}

document.getElementById("cmeforms-root") &&
	ReactDOM.createRoot(document.getElementById("cmeforms-root")).render(
		<React.StrictMode>{element}</React.StrictMode>
	);
