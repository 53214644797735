import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLocationDot,
	faCalendar,
	faSquareInfo,
	faCircleParking,
	faUser,
} from "@fortawesome/pro-light-svg-icons";

function Details() {
	return (
		<>
			<h3>
				<FontAwesomeIcon icon={faLocationDot} /> <span>Location</span>
			</h3>

			<ul>
				<li>
					The Ritz-Carlton Golf Resort, Naples
					<ul>
						<li>
							<a
								href="https://goo.gl/maps/kvnYrRDP2vxRHFfA8"
								target="_blank"
								rel="noreferrer"
							>
								2600 Tiburon Drive, Naples, FL 34109
							</a>
						</li>
						<li>Tiburon 4 Ballroom </li>
						{/*<li>(239) 593-2000</li>
            <li>
              <a
                href="https://www.ritzcarlton.com/en/hotels/naples/naples-golf"
                target="_blank"
                rel="noreferrer"
              >
                ritzcarlton.com
              </a>
            </li>*/}
					</ul>
				</li>
			</ul>

			<h3>
				<FontAwesomeIcon icon={faCalendar} /> <span>Schedule of Events</span>
			</h3>

			<ul>
				<li>
					Friday, November 17th
					<ul>
						<li>11:00 - 11:30 AM Registration</li>
						<li>11:40 AM - Event Welcome</li>
						<li>12:00 PM - Panel Discussion</li>
						<li>12:45 PM - Luncheon</li>
						<li>1:30 PM - Keynote Speaker</li>
						<li>
							2:30 PM - Networking Reception presented by Lake Michigan Credit
							Union in 18th Green Globe Lounge
						</li>
					</ul>
				</li>
			</ul>

			<h3>
				<FontAwesomeIcon icon={faCircleParking} />{" "}
				<span>Parking Information</span>
			</h3>

			<p>
				All attendees receive complimentary valet parking at The Ritz-Carlton
				Golf Resort, Naples
			</p>

			<h3>
				<FontAwesomeIcon icon={faSquareInfo} /> <span>Event Information</span>
			</h3>

			<p>
				Women’s Leadership Day Presented by Acumatica offers the attendees the
				opportunity to experience the LPGA season-ending tour championship
				firsthand and hear from our amazing speakers about Owning Your Voice in
				every aspect of life.
			</p>

			<h3>
				<FontAwesomeIcon icon={faUser} /> <span>Speakers</span>
			</h3>

			<ul>
				<li>
					Emcee
					<ul>
						<li>Chantel McCabe, Golf Personality</li>
					</ul>
				</li>
				<li>
					Keynote Speaker
					<ul>
						<li>Paula Polito, Vice Chairwoman, UBS</li>
					</ul>
				</li>
				<li>
					Panelists
					<ul>
						<li>Mary Fedewa, CEO at STORE Capital</li>
						<li>ML Groulx, VP of Human Resources at Acumatica</li>
						<li>
							Kara Hoogensen, SVP, Benefits and Protection-Head of Workplace
							Benefits
						</li>
						<li>
							Dr. Malissa Wood, Vice President & Chief Physician Executive, Lee
							Health
						</li>
					</ul>
				</li>
			</ul>
		</>
	);
}

export default Details;
